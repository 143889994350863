import React, { useState, useEffect } from "react"
import Layout from "../../components/layout/layout"

import { useStaticQuery, graphql } from "gatsby"

import ProductHeader from "../../components/product/productHeader"
import SloganSection from "../../components/product/sloganSection"
import KeyfactsSection from "../../components/product/keyfactsSection"
import IngredientsSection from "../../components/product/ingredientsSection"
import HowtoSection from "../../components/product/howtoSection"
import IconSection from "../../components/index/iconSection"
import ValuepropSection from "../../components/product/valuepropSection"
import NoplasticSection from "../../components/product/noplasticSection"
import PriceExplanation from "../../components/product/priceExplanation"
import DeliverySection from "../../components/product/deliverySection"
import TrySection from "../../components/product/trySection"
import AllReviews from "../../components/reviews/allReviews"
import FaqSection from "../../components/product/faqSection"
import CustomersBought from "../../components/product/customersBoughtSection"
import CookieConsent from "react-cookie-consent";

import { sortImages } from '../../utils/helper'

import useIsClient from '../../useIsClient';

import Seo from "../../components/layout/seo";

export default function Monatspaket() {

  const { isClient, key } = useIsClient();

  const data = useStaticQuery(graphql`
    query {
      allShopifyProduct(sort: {fields: [title]}, filter: {id: {eq: "8d651639-2db7-5e3a-a3aa-1866bb0d9f53"}}) {
        edges {
          node {
            title
            images {
              originalSrc
              id
              localFile {
                name
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                }
              }
            }
            options {
              name
              values
            }
            shopifyId
            description
            priceRangeV2 {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            id
            variants {
              id
            }
          }
        }
        nodes {
          variants {
            title
            shopifyId
            selectedOptions {
              name
              value
            }
            metafields {
              key
              value
            }
            price
            compareAtPrice
          }
          images {
            localFile {
              name
              childImageSharp {
                gatsbyImageData(height: 90, width: 90, placeholder: NONE, layout: FIXED)
              }
            }
          }
        }
      },
      productThumbnails: allShopifyProduct(sort: {fields: [title]}, filter: {id: {eq: "8d651639-2db7-5e3a-a3aa-1866bb0d9f53"}}) {
        edges {
          node {
            images {
              originalSrc
              id
              localFile {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, height: 105, width: 84, placeholder: NONE)
                }
              }
            }
          }
        }
      },
      containers: allShopifyProduct(
        sort: {fields: [title]}
        filter: {id: {in: ["44cb8ac8-7e56-50ec-8cc6-60732bd03a8f","d57350dc-38d7-5ff7-acfa-cba3d018125f"]}}
      ) {
        edges {
          node {
            title
            images {
              originalSrc
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 80, height: 80, placeholder: NONE)
                }
              }
            }
            options {
              name
              values
            }
            shopifyId
            description
            priceRangeV2 {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            id
            variants {
              id
            }
          }
        }
        nodes {
        variants {
          title
          shopifyId
          selectedOptions {
            name
            value
          }
          metafields {
            key
            value
          }
          price
          compareAtPrice
        }
        title
          images {
            originalSrc
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 80, height: 80, placeholder: NONE)
              }
            }
          }
        }
      },
      tryImg: file(relativePath: { eq: "monatspaket_freigestellt.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      customersBought: allShopifyProduct(
        sort: {fields: [title], order: DESC}
        filter: {id: {in: ["72c83be1-b5c8-5b3d-aa9a-d4c69816c66a", "46e3acb2-5819-530c-9958-2a4e07ba522e"]}}
      ) {
        edges {
          node {
            title
            images {
              originalSrc
              localFile {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, height: 650, width: 625, placeholder: NONE)
                }
              }
            }
            options {
              name
              values
            }
            shopifyId
            description
            priceRangeV2 {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            id
            variants {
              id
            }
            handle
          }
        }
        nodes {
          variants {
            title
            shopifyId
            selectedOptions {
              name
              value
            }
            metafields {
              key
              value
            }
            price
            compareAtPrice
          }
        }
      },
      zubehoerReview: allDropsReview(
        sort: {fields: createdAt, order: DESC}
        filter: {curated: {eq: true}, productIds: {ids: {in: ["7351700848874"]}}, rating: {gte: 1}}
      ) {
        totalCount
        sum(field: rating)
      },
      starterkitReview: allDropsReview(
        sort: {fields: createdAt, order: DESC}
        filter: {curated: {eq: true}, productIds: {ids: {in: ["7351701078250"]}}, rating: {gte: 1}}
      ) {
        totalCount
        sum(field: rating)
      },
      allDropsReview(
        filter: {curated: {eq: true}, productIds: {ids: {in: ["6899851854007"]}}, rating: {gte: 1}}
        sort: {fields: createdAt, order: DESC}
      ) {
        edges {
          node {
            body
            createdAt
            files
            id
            name
            rating
            title
            productIds {
              ids
            }
          }
        }
        totalCount
        sum(field: rating)
      },
      allDropsQuestion(
        filter: {curated: {eq: true}, productIds: {ids: {in: ["6899851854007"]}}}
      ) {
        edges {
          node {
            body
            curated
            name
            title
            createdAt
            productQuestionAnswers {
              body
              createdAt
            }
          }
        }
      }
    }
  `)

  // reorder images
  data.allShopifyProduct.edges[0].node.images = sortImages('localFile.name', data.allShopifyProduct.edges[0].node.images);
  data.allShopifyProduct.nodes[0].images = sortImages('localFile.name', data.allShopifyProduct.nodes[0].images);
  data.productThumbnails.edges[0].node.images = sortImages('localFile.name', data.productThumbnails.edges[0].node.images);
  data.customersBought.edges[0].node.images = sortImages('localFile.name', data.customersBought.edges[0].node.images);
  data.customersBought.edges[0].node.reviewsCount = data.zubehoerReview.totalCount;
  data.customersBought.edges[0].node.reviewsRating = (data.zubehoerReview.sum/data.zubehoerReview.totalCount).toFixed(1);
  data.customersBought.edges[1].node.images = sortImages('localFile.name', data.customersBought.edges[1].node.images);
  data.customersBought.edges[1].node.reviewsCount = data.starterkitReview.totalCount;
  data.customersBought.edges[1].node.reviewsRating = (data.starterkitReview.sum/data.starterkitReview.totalCount).toFixed(1);

  const [variantArrayData, setVariantArrayData] = useState(null);

  const [householdCounter, setHouseholdCounter] = useState(0);
  const [householdValue, setHouseholdValue] = useState(data.allShopifyProduct.edges[0].node.options[0].values[0]);
  const [monthlySupply, setMonthlySupply] = useState(0);
  const [monthlySupplyValue, setMonthlySupplyValue] = useState(data.allShopifyProduct.edges[0].node.options[1].values[0]);
  const [fluoridStatus, setFluoridStatus] = useState(0);
  const [fluoridValue, setFluoridValue] = useState(data.allShopifyProduct.edges[0].node.options[2].values[0]);
  const [containerType, setContainerType] = useState(0);
  const [activeVariantId, setActiveVariantId] = useState(data.allShopifyProduct.nodes[0].variants[0].shopifyId);
  const [activeVariantKeyString, setActiveVariantKeyString] = useState(data.allShopifyProduct.edges[0].node.options[0].values[0].replace(' ', '_')+"_"+data.allShopifyProduct.edges[0].node.options[1].values[0].replace(' ', '_')+'_'+data.allShopifyProduct.edges[0].node.options[2].values[0].replace(' ', '_'));
  const [containerItems, setContainerItems] = useState([]);
  const [containerNames, setContainerNames] = useState([]);

  useEffect(() => {
    var variantArray = {}
    var regex = /[+-]?\d+(\.\d+)?/g;
    data.allShopifyProduct.nodes[0].variants.forEach((variant, variantKey) => {
      variantArray[variant.selectedOptions[0].value.replace(' ', '_')+'_'+variant.selectedOptions[1].value.replace(' ', '_')+'_'+variant.selectedOptions[2].value.replace(' ', '_')] = {
        productTitle: containerItems.length === 0 ? data.allShopifyProduct.edges[0].node.title : data.allShopifyProduct.edges[0].node.title + containerNames.toString().replace(',', ''),
        title: variant.title,
        price: parseFloat(variant.price) + (localStorage.getItem('discount') === 'CODE40' ? parseFloat(containerItems.length/0.7513) : parseFloat(containerItems.length)),
        compareAtPrice: variant.compareAtPrice,
        shopifyId: variant.shopifyId,
        productImg: data.allShopifyProduct.nodes[0].images[0].localFile,
        tryImg: data.tryImg,
        basePrice: variant.metafields[2].value.replace(',','.').match(regex).map(function(v) { return parseFloat(v); })[0],
        containerItems: containerItems
      }
    });
    setVariantArrayData(variantArray);
  }, [data, containerItems, containerNames]);


  useEffect(() => {
    // preselect 4 month package
    setMonthlySupply(1)
    setMonthlySupplyValue("4 Monate")
    setActiveVariantKeyString("1_Person_4_Monate_Mit_Fluorid")
    setActiveVariantId("Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDM2NzgwMDUxNjc5MQ==")
  }, [])

  function handleSupplySelect(e) {
    setMonthlySupply(parseInt(e.currentTarget.dataset.key))
    setMonthlySupplyValue(e.currentTarget.dataset.value)
    setActiveVariantKeyString(householdValue.replace(' ', '_')+"_"+e.currentTarget.dataset.value.replace(' ', '_')+"_"+fluoridValue.replace(' ', '_'))
    setActiveVariantId(variantArrayData[householdValue.replace(' ', '_')+"_"+e.currentTarget.dataset.value.replace(' ', '_')+"_"+fluoridValue.replace(' ', '_')].shopifyId)
  }

  function handleFluoridSelect(e) {
    setFluoridStatus(parseInt(e.currentTarget.dataset.key))
    setFluoridValue(e.currentTarget.dataset.value)
    setActiveVariantKeyString(householdValue.replace(' ', '_')+"_"+monthlySupplyValue.replace(' ', '_')+"_"+e.currentTarget.dataset.value.replace(' ', '_'))
    setActiveVariantId(variantArrayData[householdValue.replace(' ', '_')+"_"+monthlySupplyValue.replace(' ', '_')+"_"+e.currentTarget.dataset.value.replace(' ', '_')].shopifyId)
  }

  function setHousehold(household) {
    setHouseholdCounter(parseInt(household.match(/\d+/)[0])-1)
    setHouseholdValue(household)
    setActiveVariantKeyString(household.replace(' ', '_')+"_"+monthlySupplyValue.replace(' ', '_')+"_"+fluoridValue.replace(' ', '_'))
    setActiveVariantId(variantArrayData[household.replace(' ', '_')+"_"+monthlySupplyValue.replace(' ', '_')+"_"+fluoridValue.replace(' ', '_')].shopifyId)
  }

  function handleContainerSelect(e) {
    setContainerType(parseInt(e.currentTarget.dataset.key))
    var items = containerItems;
    if (items.includes(e.currentTarget.dataset.value)) {
      // remove from array
      items = items.filter(item => item !== e.currentTarget.dataset.value)
      setContainerItems(items);
    } else {
      // add to array
      setContainerItems([...containerItems, e.currentTarget.dataset.value]);
    }

    var names = containerNames;
    if (names.includes(' + '+e.currentTarget.dataset.name)) {
      // remove from array
      names = names.filter(item => item !== ' + '+e.currentTarget.dataset.name)
      setContainerNames(names);
    } else {
      // add to array
      setContainerNames([...containerNames, ' + '+e.currentTarget.dataset.name]);
    }
  }

  const trySectionKeypoints = {
    left: ['ca. 100 Drops passen in einen Behälter', '100% Plastikfrei', 'Versandkostenfrei', 'In den Briefkasten'],
    right: ['Nachfüllpackungen für bis zu 4 Personen', '0% Kompromiss', 'Schnelle Lieferung', 'Weltretter Status']
  }

  const keypoints = { keypoints: ['Super frisch & schäumend', 'Remineralisiert den Zahnschmelz', 'Frei von Chemikalien, Parabenen & SLS', 'Versandkostenfrei', 'Made in Germany']}

  let productIds = {
  	"ids": [
  		"6899851854007"
  	]
  }

  if ( !isClient ) return null;

  return (
    <Layout activeVariant={variantArrayData[activeVariantKeyString]} key={key} containerItems={containerItems}>
      <Seo title="Drops Refill | Natürliche Minze | Toothpaste Drops" path="/products/refill" description="Über 5000 zufriedene Kunden - Natürlich - Plastikfrei - Ohne Tierversuche - Vegan - Zahnpasta Drops. Hilf uns den Plastikmüll in unserer Umwelt & den Meeren zu beenden. #weltretter" />
      <ProductHeader keypoints={keypoints} product={data.allShopifyProduct} productThumbnails={data.productThumbnails} containers={data.containers} containerItems={containerItems} variants={variantArrayData} householdCounter={householdCounter} householdValue={householdValue} activeVariantKeyString={activeVariantKeyString} activeVariantId={activeVariantId} monthlySupply={monthlySupply} monthlySupplyValue={monthlySupplyValue} fluoridStatus={fluoridStatus} fluoridValue={fluoridValue} containerType={containerType} handleFluoridSelect={handleFluoridSelect} handleSupply={handleSupplySelect} handleContainer={handleContainerSelect} setHousehold={setHousehold} sliderTitle="100% Plastikfrei + nachhaltiger Versand" reviewRating={(data.allDropsReview.sum/data.allDropsReview.totalCount).toFixed(1)} reviewCount={data.allDropsReview.edges.length} />
      <SloganSection />
      <KeyfactsSection />
      <IngredientsSection />
      <HowtoSection />
      <IconSection background={true} firstTitle="4.9" firstText={<>> 500 positive<br /> Rückmeldungen</>} firstStar={true} secondTitle="0%" secondText={<>Plastik<br /> innen & außen</>} thirdTitle="100%" thirdText={<>Made in Germany<br /> & Made by Us</>} fourthTitle="2x" fourthText={<>alle Sorten mit und<br /> ohne Fluorid</>} />
      <ValuepropSection />
      <NoplasticSection />
      <PriceExplanation />
      <DeliverySection />
      <TrySection activeVariant={variantArrayData[activeVariantKeyString]} containerItems={containerItems} ctaHook="kaufen" activeVariantId={activeVariantId} headline="Monatspaket" subhl={<><p>Den Nachfüllbehälter kannst du immer wieder befüllen.<br /> Einfach für dich, gut für die Umwelt.</p></>} keypoints={trySectionKeypoints} image={data.tryImg} />
      <AllReviews reviews={data.allDropsReview} questions={data.allDropsQuestion} reviewRating={(data.allDropsReview.sum/data.allDropsReview.totalCount).toFixed(1)} productIds={productIds} />
      <FaqSection />
      <CustomersBought data={data.customersBought} />
      <CookieConsent style={{ background: "#000000", color: "#ffffff", fontSize: "14px", alignItems: "center" }} contentClasses="m-2" buttonClasses="m-2" buttonStyle={{ color: "#ffffff", background: "#0275d8", fontSize: "13px" }} buttonText="OK">Zur Bereitstellung von Diensten verwenden wir Cookies. Durch die Nutzung dieser Website stimmen Sie zu.</CookieConsent>
    </Layout>
  )
}
